// Keeping this code out of the main component avoid rerenders on the confirmed causes list
import {FC, useState} from "react"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import {useTranslation} from "@frontend/i18n"
import {UiCauses} from "@ri2/app/server/routers/getUiCauses"
import {Case} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {CausesList} from "./CausesList"
import {ToggleButton} from "./ToggleButton"

interface Props {
  caseModel: Case
  causes: UiCauses
}

const POSSIBLE_TAB = "possible"
const RULED_OUT_TAB = "ruledOut"

type CauseTabs = typeof POSSIBLE_TAB | typeof RULED_OUT_TAB

export const UnconfirmedCauses: FC<Props> = ({caseModel, causes}) => {
  const t = useTranslation()
  const [selectedTab, setSelectedTab] = useState<CauseTabs>(POSSIBLE_TAB)

  const otherSelectedCauses = causes[selectedTab]

  const colorVariant =
    selectedTab === POSSIBLE_TAB
      ? ("informational" as const)
      : ("warning" as const)

  return (
    <div className={css({marginTop: 49})}>
      <div
        className={hstack({
          justifyContent: "space-between",
          gap: 1,
          width: "100%",
          marginBottom: 8,
          alignItems: "center",
          desktopOnly: {
            flexDirection: "column",
            gap: 4,
            alignItems: "flex-start",
          },
        })}
      >
        <h2
          className={css({
            color: "text.primary",
            textStyle: "bodyLargeMedium",
            letterSpacing: "-0.01em",
          })}
        >
          {t(
            caseModel.messages.some(
              (message) => message.mode === "ASK_DIAGNOSTIC_QUESTION",
            )
              ? "cases.case.otherCauses"
              : "cases.case.causes",
          )}
        </h2>
        <div
          className={hstack({
            padding: 4,
            gap: 6,
            borderRadius: 999,
            backgroundColor: "background.tertiary",
            width: "fit-content",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "border.primary.300",
          })}
        >
          <ToggleButton
            selected={selectedTab === POSSIBLE_TAB}
            title={t("cases.case.possible")}
            onSelected={() => setSelectedTab(POSSIBLE_TAB)}
            count={causes.possible.length}
          />
          <ToggleButton
            selected={selectedTab === RULED_OUT_TAB}
            title={t("cases.case.ruledOut")}
            onSelected={() => setSelectedTab(RULED_OUT_TAB)}
            count={causes.ruledOut.length}
          />
        </div>
      </div>
      {otherSelectedCauses.length > 0 ? (
        <CausesList causes={otherSelectedCauses} caseId={caseModel.id} />
      ) : (
        <InformationBanner
          content={
            selectedTab === POSSIBLE_TAB
              ? t(`cases.case.noOtherCausesMessage.possible`)
              : t(`cases.case.noOtherCausesMessage.ruledOut`)
          }
          variant={colorVariant}
        />
      )}
    </div>
  )
}
