import {AnimatePresence, motion} from "framer-motion"
import {FC, useEffect, useMemo, useState} from "react"

import {CauseOnMessageWithCauseAndPart} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {Cause, type CauseStatus} from "./cause"
import {generateIconBackgrounds} from "./utils"

interface CausesListProps {
  causes: CauseOnMessageWithCauseAndPart[]
  caseId: string
  causeStatus?: CauseStatus
}

const SPRING = {
  type: "spring",
  damping: 25,
  stiffness: 120,
  duration: 2,
  staggerDirection: 1,
}

export const CausesList: FC<CausesListProps> = ({
  causes,
  caseId,
  causeStatus,
}) => {
  const [highlightedCauseId, setHighlightedCause] = useState<number | null>(
    null,
  )

  const iconBackgrounds = useMemo(
    () => generateIconBackgrounds(causes.length),
    [causes.length],
  )

  useEffect(() => {
    if (causeStatus === "confirmed") {
      setHighlightedCause(causes[0]?.id)
    }
  }, [causeStatus, causes])
  return (
    <ul
      className={css(
        {
          display: "flex",
          flexDirection: "column",
        },
        causeStatus == "confirmed" && {
          gap: 8,
        },
      )}
    >
      {/* The key is used to stop it from animating when the cause likelihood changes, since that's just the user toggling between different lists */}
      <AnimatePresence initial={false}>
        {causes.map((causeOnMessage, index) => (
          <motion.li
            key={causeOnMessage.causeId}
            layout
            transition={SPRING}
            initial={{
              opacity: 0,
              y: 0,
              scale: 1,
              zIndex: causes.length - index,
            }}
            animate={{
              opacity: 1,
              y: 0,
              scale: 1,
              zIndex: causes.length - index,
            }}
            exit={{
              opacity: 0,
              y: 50,
              scale: 0.9,
              zIndex: causes.length - index,
            }}
          >
            <Cause
              caseId={caseId}
              iconBackground={iconBackgrounds[index]}
              cause={causeOnMessage}
              causeStatus={causeStatus}
              setHoveredItem={setHighlightedCause}
              isHighlighted={highlightedCauseId === causeOnMessage.id}
            />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  )
}
