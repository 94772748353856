import type {Cause, CauseOnMessage} from "@prisma/client"
import {Part} from "@ri2/rc-db"

import {Message} from "../client"

const confirmationThreshold = 0.9

export const isProbabilityConfirmed = (probability: number): boolean =>
  probability >= confirmationThreshold

export const isCauseOnMessageConfirmed = (
  cause: CauseOnMessageWithCauseAndPart,
): boolean => isProbabilityConfirmed(cause.probability)

export const getConfirmedCausesFromMessage = (
  message: Message,
): CauseOnMessageWithCauseAndPart[] =>
  message.type === "ai" ? message.causes.filter(isCauseOnMessageConfirmed) : []

export type CauseOnMessageWithCauseAndPart = CauseOnMessage & {
  cause: Cause
  part?: Part
}
